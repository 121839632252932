import { useQuery } from '@apollo/client';
import { SecurityClass } from '@bakerweb/models';
import { GET_SECURITY_CLASSES } from '../graphql/queries/getSecurityClasses';

export const useGetSecurityClasses = (skip = 0, take = 1000, secClasses = null) => {
  const { loading, error, data, refetch } = useQuery<{ securityClasses: SecurityClass[] }>(GET_SECURITY_CLASSES, {
    variables: { skip, take, secClasses },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first'
  });

  const securityClasses = data?.securityClasses ?? [];

  return { loading, error, securityClasses, refetch };
};
