import { useCognitoGroups } from '@bakerweb/auth';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

export const BAKER_PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    dashboard: path(ROOTS_DASHBOARD, '/lseg'),
    client: path(ROOTS_DASHBOARD, '/client/:portfolioId'),
    admin: path(ROOTS_DASHBOARD, '/admin'),
    risk: path(ROOTS_DASHBOARD, '/admin/risk'),
    upload: path(ROOTS_DASHBOARD, '/admin/upload'),
    search: path(ROOTS_DASHBOARD, '/search'),
    apm: path(ROOTS_DASHBOARD, '/apm'),
    irrm: path(ROOTS_DASHBOARD, '/reports'),
    cusip: path(ROOTS_DASHBOARD, '/client/:portfolioId/cusip/:positionId')
  },
  loans: {
    root: path(ROOTS_DASHBOARD, '/loans'),
    autos: path(ROOTS_DASHBOARD, '/loans/autos'),
    consumers: path(ROOTS_DASHBOARD, '/loans/consumers'),
    cre: path(ROOTS_DASHBOARD, '/loans/cre'),
    general: path(ROOTS_DASHBOARD, '/loans/general'),
    residential: path(ROOTS_DASHBOARD, '/loans/residential')
  },
  admin: {
    root: path(ROOTS_DASHBOARD, '/admin'),
    client: path(ROOTS_DASHBOARD, '/admin/clients'),
    newUser: path(ROOTS_DASHBOARD, '/admin/users/new'),
    editUser: (userId: string) => path(ROOTS_DASHBOARD, `/admin/users/${userId}`)
  }
};

type Config = {
  subheader: string;
  items: {
    title: string;
    path: string;
    icon?: React.ReactElement;
    children?: {
      title: string;
      path: string;
    }[];
  }[];
};

export const useBakerSidebarConfig = () => {
  const { isAdmin, isLoans, isLseg } = useCognitoGroups();

  const config: Config[] = [];

  if (isLseg) {
    config.push({
      subheader: '',
      items: [
        {
          title: 'Daily Pricing',
          path: BAKER_PATH_DASHBOARD.general.dashboard,
          icon: <MonetizationOnIcon />
        }
      ]
    });
  }
  if (isLoans) {
    config.push({
      subheader: 'Loans',
      items: [
        {
          title: 'Loans (Beta)',
          path: BAKER_PATH_DASHBOARD.loans.root,
          icon: <CreditScoreIcon />,
          children: [
            { title: 'Autos', path: BAKER_PATH_DASHBOARD.loans.autos },
            { title: 'Consumers', path: BAKER_PATH_DASHBOARD.loans.consumers },
            { title: 'CRE', path: BAKER_PATH_DASHBOARD.loans.cre },
            { title: 'General', path: BAKER_PATH_DASHBOARD.loans.general },
            { title: 'Residential', path: BAKER_PATH_DASHBOARD.loans.residential }
          ]
        }
      ]
    });
  }
  if (isAdmin) {
    config.push({
      subheader: 'Admin',
      items: [
        {
          title: 'Admin',
          path: BAKER_PATH_DASHBOARD.admin.root,
          icon: <AdminPanelSettingsIcon />,
          children: [{ title: 'Client Portal', path: BAKER_PATH_DASHBOARD.admin.client }]
        }
      ]
    });
  }

  return config;
};
