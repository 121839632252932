import { useQuery } from '@apollo/client';
import { SafeKeepingCode } from '@bakerweb/models';
import { alphabetizeSafekeeping } from '@bakerweb/utils';
import { GET_SK_INSTITUTIONS } from '../graphql/queries/getSkInstitutions';

export const useGetSkInstitutions = () => {
  const { loading, error, data } = useQuery<{ safeKeepingInstitutions: SafeKeepingCode[] }>(GET_SK_INSTITUTIONS);

  const safeKeepingInstitutions = data?.safeKeepingInstitutions
    ? alphabetizeSafekeeping(data.safeKeepingInstitutions)
    : [];

  return { loading, error, safeKeepingInstitutions };
};
