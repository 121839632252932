import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { UPDATE_SAFE_KEEPING_POSITIONS } from '../graphql/mutations/updateSafeKeepingPositions';

export const useUpdateSafeKeepingPositions = () => {
  const [updateSafeKeepingPositionsMutation, { data, loading, error }] = useMutation(UPDATE_SAFE_KEEPING_POSITIONS);

  const updateSafeKeepingPositions = async (safeKeepingPositionUpdateDtos: any) => {
    if (!safeKeepingPositionUpdateDtos || safeKeepingPositionUpdateDtos.length === 0) {
      toast.error('No positions provided to update.');
      return;
    }
    try {
      await updateSafeKeepingPositionsMutation({
        variables: { safeKeepingPositionUpdateDtos }
      });
      toast.success('Positions updated successfully');
    } catch (err) {
      toast.error('There was an error updating positions.');
      console.error('Error updating positions:', err);
    }
  };

  return { updateSafeKeepingPositions, data, loading, error };
};
