export const fullAddress = [
  '19034 Verna Unions Apt. 164lulu, RI / 87535',
  '1147 Rohan Drive Suite 819ington, VT / 82021',
  '18605 Thompson Circle Apt. 086o Falls, WV / 50337',
  '110 Lamar Station Apt. 730rstown, OK / 49808',
  '36901 Elmer Spurs Apt. 762mar, DE / 92836',
  '2089 Runolfsson Harbors Suite 886el Hill, TX / 32827',
  '279 Karolann Ports Apt. 774cott Valley, WV / 53905',
  '96607 Claire Square Suite 591Louis Park, HI / 40802',
  '9388 Auer Station Suite 573lulu, AK / 98024',
  '47665 Adaline Squares Suite 510ksburg, NE / 53515',
  '989 Vernice Flats Apt. 183ings, NV / 04147',
  '91020 Wehner Locks Apt. 673ny, WY / 68763',
  '585 Candelario Pass Suite 090mbus, LA / 25376',
  '80988 Renner Crest Apt. 000o, VA / 24266',
  '28307 Shayne Pike Suite 523h Las Vegas, AZ / 28550',
  '205 Farrell Highway Suite 333 Hill, OK / 63421',
  '253 Kara Motorway Suite 821hester, SD / 09331',
  '13663 Kiara Oval Suite 606oula, AR / 44478',
  '8110 Claire Port Apt. 703orage, TN / 01753',
  '4642 Demetris Lane Suite 407nd, AZ / 60888',
  '74794 Asha Flat Suite 890aster, OR / 13466',
  '8135 Keeling Pines Apt. 326andria, MA / 89442',
  '441 Gibson Shores Suite 247o, NM / 60678',
  '4373 Emelia Valley Suite 596mbia, NM / 42586',
  '1946 Strosin Creek Apt. 279er, CO / 21335',
  '147 Rempel Center Apt. 305ennial, TN / 09375',
  '78481 Floyd Mountains Apt. 184dale, SD / 18777',
  '129 Toy Pines Suite 339o Falls, CT / 82070',
  '08945 Cremin Underpass Apt. 700 City, GA / 33243',
  '20728 Dorcas Highway Suite 959o, ND / 99931',
  '492 Wilburn Park Apt. 746evue, TN / 16077',
  '70530 Garfield Tunnel Suite 902oit, IA / 01664',
  '868 Kautzer Grove Suite 334ny, KS / 51368',
  '71189 Towne Forks Suite 519orage, IL / 52636',
  '5070 Magdalena Neck Suite 096r Rapids, PA / 01342',
  '6216 Jermain Walk Apt. 279ington, MA / 55416',
  '1795 Kilback Stravenue Apt. 745ord, MA / 96099',
  '831 Rolfson Expressway Apt. 878an, NY / 10239',
  '909 Nathaniel Lake Suite 769man, NJ / 46523',
  '3690 Hans Ports Apt. 369, KS / 48930'
];

export const country = [
  'Kenya',
  'Madagascar',
  'Netherlands Antilles',
  'Azerbaijan',
  'Aruba',
  'Comoros',
  'Sierra Leone',
  'Bermuda',
  'Italy',
  'Iran',
  'Denmark',
  'Congo',
  'Cambodia',
  'Virgin Islands, British',
  'Bahamas',
  'Italy',
  'France',
  'Portugal',
  'Nepal',
  'Saint Vincent and the Grenadines',
  'Greenland',
  'Maldives',
  'Comoros',
  'Bhutan',
  'Tuvalu',
  'Ethiopia',
  'Myanmar',
  'Libyan Arab Jamahiriya',
  'Senegal',
  'Malta',
  'Kyrgyz Republic',
  'Turks and Caicos Islands',
  'Gibraltar',
  'Sweden',
  'Zambia',
  'Isle of Man',
  'Canada',
  'Turkey',
  'Samoa',
  'Vietnam'
];

export const states = [
  'AK',
  'AL',
  'AR',
  'ASa',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'GU',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VI',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY'
];
