import { gql } from '@apollo/client';

export const GET_SECURITY_CLASSES = gql`
  query SecurityClasses($skip: Int = 0, $take: Int = 1000, $secClasses: [Int!]) {
    securityClasses(skip: $skip, take: $take, secClasses: $secClasses) {
      secClass
      secGroup
      longDesc
      shortDesc
      sortOrder
      sortOrderForInventory
      isVariable
      classB1GLAccountNumbers
      classB1GLDesc
      classB1GLSortOrder
      mediumDesc
      awayBoughtSalesCredit
      awaySellSalesCredit
    }
  }
`;
