import { gql } from '@apollo/client';

export const DELETE_USER_MUTATION = gql`
  mutation DeleteUser($userDeleteDto: UserDeleteDto!) {
    deleteUser(userDeleteDto: $userDeleteDto) {
      success
      errorMessage
    }
  }
`;
