import { useCallback, useState } from 'react';
import { clientHttp } from '../client.http';

interface Result {
  success: string[];
  errors: unknown[];
}

export const useS3PrefixList = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>([]);
  const [success, setSuccess] = useState<FileList[]>([]);

  const getPrefixes = useCallback(async (path: string): Promise<Result> => {
    setLoading(true);
    setError([]);
    setSuccess([]);

    try {
      const urlFragment = `/object/list-prefixes/${encodeURIComponent(path)}`;
      const response = await clientHttp(urlFragment, {
        method: 'GET'
      });

      setSuccess(response);
      return { success: response, errors: response.errors || [] };
    } catch (error) {
      setError(error);
      return { success: [], errors: [error] };
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    error,
    success,
    getPrefixes
  };
};
