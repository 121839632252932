import { useQuery } from '@apollo/client';
import { Institution } from '@bakerweb/models';
import { GET_INSTITUTIONS } from '../graphql/queries/getInstitutions';

type InstitutionIds = string[] | null;

export const useGetInstitutions = (ids: InstitutionIds = null) => {
  const { loading, error, data, refetch } = useQuery<{ institutions: Institution[] }>(GET_INSTITUTIONS, {
    variables: {
      institutionIds: ids
    }
  });

  const institutions = data?.institutions ?? [];

  return { loading, error, institutions, refetch };
};
