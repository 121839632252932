import { useGetSkInstitutions, useUpdateSafeKeepingPositions } from '@bakerweb/client-services';
import { selectedPortfolioState } from '@bakerweb/client-state';
import { Pledge, SafeKeepingPosition, ValidPosition } from '@bakerweb/models';
import {
  fCurrency,
  fCurrencyForPledgingGrid,
  genRandomRecIdForDataGrid,
  squishSafeKeepingPositions
} from '@bakerweb/utils';
import CloseIcon from '@mui/icons-material/Close';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import RestoreIcon from '@mui/icons-material/Restore';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  DrawerProps,
  FormControl,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';
import {
  DataGridPremium,
  DataGridPremiumProps,
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridValidRowModel,
  useGridApiRef
} from '@mui/x-data-grid-premium';
import { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { Iconify } from '../iconify/Iconify';
import { Label } from '../Label';
import { Scrollbar } from '../Scrollbar';
import { PledgeCodesModal } from './PledgeCodesModal';

interface Props extends DrawerProps {
  loading: boolean;
  position: ValidPosition;
  onClose: VoidFunction;
  pledgeCodes: Pledge[];
  asOfDate: string;
  refetchPositions: () => void;
}

interface SelectedCellParams {
  id: GridRowId;
  field: string;
}

export function PledgingFlyoutDrawer({
  loading,
  pledgeCodes,
  position,
  asOfDate,
  refetchPositions,
  open,
  onClose,
  ...other
}: Props) {
  const { safeKeepingPositions } = position;
  const isMobile = useMediaQuery('(max-width:900px)');
  const [selectedCellParams, setSelectedCellParams] = useState<SelectedCellParams | null>(null);
  const [releaseAllPledgesAcknowledged, setReleaseAllPledgesAcknowledged] = useState(false);
  const [rows, setRows] = useState(safeKeepingPositions);
  const apiRef = useGridApiRef();
  const { updateSafeKeepingPositions } = useUpdateSafeKeepingPositions();
  const { safeKeepingInstitutions, loading: skLoading } = useGetSkInstitutions();
  const portfolio = useRecoilValue(selectedPortfolioState);
  const portfolioId = portfolio?.portfolioId;
  const [hasUnsavedRows, setHasUnsavedRows] = useState(false);
  const unsavedChangesRef = useRef<{
    unsavedRows: Record<GridRowId, GridValidRowModel>;
    rowsBeforeChange: Record<GridRowId, GridValidRowModel>;
  }>({
    unsavedRows: {},
    rowsBeforeChange: {}
  });
  const [isSaving, setIsSaving] = useState(false);
  const [totalPledgedInGrid, setTotalPledgedInGrid] = useState(0);

  useEffect(() => {
    if (position) {
      setRows(position.safeKeepingPositions);
      setTotalPledgedInGrid(position.origFace - position.safeKeepingPledgedSum);
    }
  }, [position]);

  const columns = useMemo<GridColDef[]>(() => {
    return [
      {
        field: 'safekeepingCode',
        flex: 1,
        headerName: 'SK Code',
        editable: true,
        type: 'singleSelect',
        valueOptions: safeKeepingInstitutions.map((p) => p.safeKeepingCode),
        valueGetter: (value, row) => row.safekeepingCode || '',
        valueSetter: (value, row) => {
          const selectedSkCode = value;
          return { ...row, safekeepingCode: selectedSkCode };
        }
      },
      {
        field: 'pledgee',
        flex: 1,
        headerName: 'Pledgee',
        editable: true,
        type: 'singleSelect',
        valueOptions: pledgeCodes.map((p) => p.pledgeeName),
        valueSetter: (value, row) => {
          const selectedPledgeeName = value;
          const selectedPledgee = pledgeCodes.find((p) => p.pledgeeName === selectedPledgeeName);
          const newPledgee = selectedPledgee
            ? { pledgeeName: selectedPledgee.pledgeeName, pledgeCode: selectedPledgee.pledgeCode }
            : null;
          return { ...row, pledgee: newPledgee };
        },
        valueGetter: (value, row) => {
          return row.pledgee?.pledgeeName?.toUpperCase() || 'NOT PLEDGED';
        }
      },
      {
        field: 'pledgedAmount',
        headerName: 'Original Face',
        flex: 1,
        type: 'number',
        editable: true,
        valueFormatter: (value) => fCurrency(value)
      },
      {
        field: 'currFace',
        headerName: 'Current Face',
        flex: 1,
        type: 'number',
        editable: false,
        valueFormatter: (value) => fCurrency(value)
      },
      {
        field: 'actions',
        type: 'actions',
        minWidth: 80,
        headerName: 'Actions',
        getActions: ({ id, row }) => [
          <GridActionsCellItem
            icon={<RestoreIcon />}
            label="Undo"
            title="Undo"
            disabled={unsavedChangesRef.current.unsavedRows[id] === undefined}
            onClick={() => {
              apiRef.current.updateRows([unsavedChangesRef.current.rowsBeforeChange[id]]);
              delete unsavedChangesRef.current.rowsBeforeChange[id];
              delete unsavedChangesRef.current.unsavedRows[id];
              setHasUnsavedRows(Object.keys(unsavedChangesRef.current.unsavedRows).length > 0);
            }}
          />,
          <GridActionsCellItem
            icon={<LockOpenIcon />}
            label="Release"
            title="Release"
            onClick={() => {
              const releasedRow = {
                ...row,
                pledgee: { pledgeeName: 'NOT PLEDGED', pledgeCode: '-1' },
                _action: 'release'
              };
              apiRef.current.updateRows([releasedRow]);
              unsavedChangesRef.current.unsavedRows[id] = releasedRow;
              if (!unsavedChangesRef.current.rowsBeforeChange[id]) {
                unsavedChangesRef.current.rowsBeforeChange[id] = row;
              }
              setHasUnsavedRows(true);
            }}
          />
        ]
      }
    ];
  }, [position, safeKeepingInstitutions, unsavedChangesRef]);

  const calculatePledgedDifferences = (pledges?: SafeKeepingPosition[]) => {
    const localPledges = pledges ? [...pledges] : [...position.safeKeepingPositions];
    const skCodeForPledge: string = localPledges[0].safekeepingCode;
    const sumPledgedAmount = localPledges.reduce((sum, { pledgedAmount }) => sum + pledgedAmount, 0);
    const difference = position.origFace - sumPledgedAmount;
    return {
      difference,
      skCodeForPledge
    };
  };

  const getAndTransformAllGridPledges = () => {
    const allCurrentPledges = apiRef.current
      .getAllRowIds()
      .map((id) => apiRef.current.getRow(id))
      .filter(Boolean);

    return allCurrentPledges.map((position) => {
      const { recId, safeKeepingName, currFace, pledgee, __typename, _action, ...rest } = position;
      if (pledgee?.pledgeCode) {
        rest.pledgeCode = pledgee.pledgeCode;
      }
      return { asOfDate, portfolioId, ...rest };
    });
  };

  const processRowUpdate: NonNullable<DataGridPremiumProps['processRowUpdate']> = (newRow, oldRow) => {
    const rowId = newRow.recId;
    unsavedChangesRef.current.unsavedRows[rowId] = newRow;
    if (!unsavedChangesRef.current.rowsBeforeChange[rowId]) {
      unsavedChangesRef.current.rowsBeforeChange[rowId] = oldRow;
    }
    setHasUnsavedRows(true);
    return newRow;
  };

  const discardChanges = () => {
    setHasUnsavedRows(false);
    const originalRowIds = new Set(position.safeKeepingPositions.map((row: SafeKeepingPosition) => row.recId));
    const currentRowIds = new Set(rows.map((row: SafeKeepingPosition) => row.recId));
    const addedRowIds = [...currentRowIds].filter((id) => !originalRowIds.has(id));
    // Filter out any unsaved added inline pledges to the DataGrid
    const updatedRows = rows.filter((row: SafeKeepingPosition) => !addedRowIds.includes(row.recId));
    // Reset rows for DataGrid
    setRows(updatedRows);
    setTotalPledgedInGrid(position.origFace - position.safeKeepingPledgedSum);

    apiRef.current.updateRows(Object.values(unsavedChangesRef.current.rowsBeforeChange));
    unsavedChangesRef.current = {
      unsavedRows: {},
      rowsBeforeChange: {}
    };
  };

  const saveAllChanges = async () => {
    const transformedPledges = getAndTransformAllGridPledges();
    const pledgedDiffs = calculatePledgedDifferences(transformedPledges);
    const { difference, skCodeForPledge } = pledgedDiffs;

    if (difference < 0) {
      toast.error(
        'The pledged amounts exceed the original face of this position. Please update original faces of each pledge so that the total pledges are equal to the original face.'
      );
      return;
    }
    if (difference > 0) {
      const unpledgedPosition = {
        portfolioId,
        asOfDate,
        positionMasterId: position.positionMasterId,
        safekeepingCode: skCodeForPledge,
        pledgeCode: '-1', // Code for Not Pledged
        pledgedAmount: difference
      };
      transformedPledges.push(unpledgedPosition);
    }

    const consolidatedPositions = squishSafeKeepingPositions(transformedPledges);

    try {
      setIsSaving(true);
      const safeKeepingPositionUpdateDtos = {
        positionMasterId: position.positionMasterId,
        asOfDate,
        portfolioId,
        safeKeepingPositions: consolidatedPositions
      };
      await updateSafeKeepingPositions(safeKeepingPositionUpdateDtos);
      await refetchPositions();
      setHasUnsavedRows(false);
      unsavedChangesRef.current = {
        unsavedRows: {},
        rowsBeforeChange: {}
      };
    } catch (error) {
      console.error(error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleReleasePledgesAcknowledged = () => {
    setReleaseAllPledgesAcknowledged(!releaseAllPledgesAcknowledged);
  };

  const saveReleaseAllPledges = async () => {
    const uniqueSafeKeepingPositions = position.safeKeepingPositions.map((position: SafeKeepingPosition) => ({
      portfolioId,
      asOfDate,
      positionMasterId: position.positionMasterId,
      safekeepingCode: position.safekeepingCode,
      pledgeCode: '-1', // Code for Not Pledged
      pledgedAmount: position.pledgedAmount
    }));
    const consolidatedPositions = squishSafeKeepingPositions(uniqueSafeKeepingPositions);
    try {
      setIsSaving(true);
      const safeKeepingPositionUpdateDtos = {
        positionMasterId: position.positionMasterId,
        asOfDate,
        safeKeepingPositions: consolidatedPositions
      };
      await updateSafeKeepingPositions(safeKeepingPositionUpdateDtos);
      setReleaseAllPledgesAcknowledged(false);
      await refetchPositions();
    } catch (error) {
      console.error('Error releasing pledges: ', error);
    } finally {
      setIsSaving(false);
    }
  };

  const createAdditionalPledge = () => {
    const transformedPledges = getAndTransformAllGridPledges();
    const pledgedDiffs = calculatePledgedDifferences(transformedPledges);
    const { difference, skCodeForPledge } = pledgedDiffs;
    return {
      recId: genRandomRecIdForDataGrid(),
      safekeepingCode: skCodeForPledge,
      pledgeCode: '-1',
      pledgee: 'NOT PLEDGED',
      pledgedAmount: difference,
      currFace: 0,
      positionMasterId: position.positionMasterId,
      portfolioId,
      asOfDate
    };
  };

  const handleAddRow = () => {
    const newPledge = createAdditionalPledge();
    apiRef.current.updateRows([newPledge].reverse());
    unsavedChangesRef.current.unsavedRows[newPledge.recId] = newPledge;
    setHasUnsavedRows(true);
  };

  const performInlinePledgedCalculation = () => {
    const allCurrentPledgesInGrid = apiRef.current
      .getAllRowIds()
      .map((id) => apiRef.current.getRow(id))
      .filter(Boolean);
    const pledgedSum = allCurrentPledgesInGrid
      .filter((pledge) => pledge.pledgee.pledgeCode !== '-1')
      .reduce((sum, { pledgedAmount }) => sum + pledgedAmount, 0);
    setTotalPledgedInGrid(position.origFace - pledgedSum);
  };

  const success = { color: 'success.dark', border: 0 };
  const warning = { color: 'warning.dark', border: 0 };
  const error = { color: 'error.main', border: 0 };

  const renderPledgedLabel = () => {
    if (totalPledgedInGrid === 0) {
      return (
        <Label variant="outlined" sx={success}>
          <Iconify icon="eva:checkmark-circle-outline" sx={{ mr: 1 }} /> Fully Pledged
        </Label>
      );
    } else if (totalPledgedInGrid < 0) {
      return (
        <Label variant="outlined" sx={error}>
          <Iconify icon="eva:alert-triangle-outline" sx={{ mr: 1 }} /> Exceeds Max Pledge:{' '}
          {fCurrencyForPledgingGrid(totalPledgedInGrid)}
        </Label>
      );
    } else {
      return (
        <Label variant="outlined" sx={warning}>
          <Iconify icon="eva:info-outline" sx={{ mr: 1 }} /> Remaining To Pledge:{' '}
          {fCurrencyForPledgingGrid(totalPledgedInGrid)}
        </Label>
      );
    }
  };

  const { cusip, currFaceEnding, origFace, safeKeepingPledgedSum, marketValue } = position;

  return (
    <>
      <Drawer
        open={open}
        onClose={onClose}
        anchor="right"
        BackdropProps={{
          invisible: false
        }}
        PaperProps={{
          sx: { width: isMobile ? '100%' : '900px' }
        }}
        {...other}
      >
        <Scrollbar sx={{ height: 1 }}>
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2.5 }}>
            <Typography variant="h6"> Pledge Details </Typography>
            <IconButton color="inherit" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Stack>
          <Divider sx={{ borderStyle: 'dashed' }} />
          <Stack spacing={2.5} justifyContent="center" sx={{ p: 2.5 }}>
            <Stack direction="column">
              <Typography variant="subtitle2" sx={{ wordBreak: 'break-all' }}>
                CUSIP
              </Typography>
              <Typography variant="h4" color="primary">
                {cusip}
              </Typography>
            </Stack>
            <Divider sx={{ borderStyle: 'dashed' }} />
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="column">
                <Typography variant="subtitle2" sx={{ wordBreak: 'break-all' }}>
                  Original Face
                </Typography>
                <Typography variant="h4" color="success.dark">
                  {fCurrency(origFace)}
                </Typography>
              </Stack>

              <Stack direction="column">
                <Typography variant="subtitle2" sx={{ wordBreak: 'break-all' }}>
                  Current Face
                </Typography>
                <Typography variant="h4" color="success.dark">
                  {fCurrency(currFaceEnding)}
                </Typography>
              </Stack>

              <Stack direction="column">
                <Typography variant="subtitle2" sx={{ wordBreak: 'break-all' }}>
                  Market Value
                </Typography>
                <Typography variant="h4" color="success.dark">
                  {fCurrency(marketValue)}
                </Typography>
              </Stack>

              <Stack direction="column">
                <Typography variant="subtitle2" sx={{ wordBreak: 'break-all' }}>
                  Pledged Amount
                </Typography>
                <Typography variant="h4" color="success.dark">
                  {fCurrency(safeKeepingPledgedSum)}
                </Typography>
              </Stack>
            </Stack>

            <Divider sx={{ borderStyle: 'dashed' }} />

            <Stack>
              <Stack direction="row" justifyContent="space-between" sx={{ mb: 2.5 }}>
                <Typography variant="subtitle2"> Current Pledges </Typography>
                {renderPledgedLabel()}
                <Button color="primary" size="small" variant="contained" onClick={handleAddRow}>
                  Add Additional Pledge
                </Button>
              </Stack>
              <div style={{ width: '100%' }}>
                <DataGridPremium
                  rows={rows}
                  apiRef={apiRef}
                  disableRowSelectionOnClick
                  processRowUpdate={processRowUpdate}
                  getRowId={(row) => row.recId}
                  columns={columns}
                  loading={isSaving || loading || skLoading}
                  onCellModesModelChange={() => {
                    performInlinePledgedCalculation();
                  }}
                  slots={{
                    noRowsOverlay: () => {
                      return (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            fontWeight: 'bolder'
                          }}
                        >
                          No Pledges
                        </Box>
                      );
                    }
                  }}
                  getRowClassName={({ id }) => {
                    const unsavedRow = unsavedChangesRef.current.unsavedRows[id];
                    if (unsavedRow) {
                      if (unsavedRow['_action'] === 'release') {
                        return 'row--release';
                      }
                      return 'row--edited';
                    }
                    return '';
                  }}
                  slotProps={{
                    toolbar: {
                      selectedCellParams,
                      setSelectedCellParams
                    }
                  }}
                  hideFooter
                  sx={{
                    '& .MuiDataGrid-row.row--release': {
                      backgroundColor: (theme) => {
                        if (theme.palette.mode === 'light') {
                          return 'rgba(255, 170, 170, 0.3)';
                        }
                        return 'rgb(143,47,47, 0.6)';
                      }
                    },
                    '& .MuiDataGrid-row.row--edited': {
                      backgroundColor: (theme) => {
                        if (theme.palette.mode === 'light') {
                          return 'rgba(222,222,10,0.3)';
                        }
                        return 'rgb(100,106,84, .6)';
                      }
                    }
                  }}
                />
              </div>
            </Stack>

            <Stack>
              <Stack spacing={2} direction="column" alignItems={{ xs: 'flex-start', md: 'center' }}>
                <Stack spacing={2} direction="row" alignSelf={{ xs: 'normal', md: 'normal' }}>
                  <Button
                    fullWidth
                    color="error"
                    variant="contained"
                    disabled={!hasUnsavedRows || isSaving}
                    onClick={discardChanges}
                  >
                    Discard All Changes
                  </Button>
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    disabled={!hasUnsavedRows}
                    loading={isSaving}
                    startIcon={<Iconify icon="eva:checkmark-circle-outline" />}
                    onClick={saveAllChanges}
                  >
                    <span>Save All Changes</span>
                  </LoadingButton>
                </Stack>
                <PledgeCodesModal />
              </Stack>
            </Stack>

            <Stack>
              <Stack direction="row" justifyContent="space-between" sx={{ mb: 1 }}>
                <Typography variant="subtitle2" color="error">
                  Release All Pledges For CUSIP
                </Typography>
              </Stack>
              <Stack spacing={2} direction="column" alignItems={{ xs: 'flex-start', md: 'center' }}>
                <FormControl fullWidth>
                  <FormControlLabel
                    color="error"
                    control={
                      <Checkbox
                        color="error"
                        checked={releaseAllPledgesAcknowledged}
                        onChange={handleReleasePledgesAcknowledged}
                      />
                    }
                    label={`I am aware this will release all pledges for ${cusip}.`}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ mt: 3 }}>
                  <Button
                    fullWidth
                    disabled={!releaseAllPledgesAcknowledged}
                    variant="contained"
                    color="error"
                    startIcon={<Iconify icon="eva:alert-circle-outline" />}
                    onClick={saveReleaseAllPledges}
                  >
                    Release All Pledges For CUSIP
                  </Button>
                </FormControl>
              </Stack>
            </Stack>
          </Stack>
        </Scrollbar>
      </Drawer>
    </>
  );
}
