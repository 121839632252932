import { useMutation } from '@apollo/client';
import { selectedPortfolioState } from '@bakerweb/client-state';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { UPDATE_AS_OF_DATE } from '../graphql/mutations/updateAsOfDate';

interface UpdateAsOfDate {
  asOfDate: string;
  updateData: any; // need to come back and cover permutations
}

export const useUpdateAsOfDate = () => {
  const portfolio = useRecoilValue(selectedPortfolioState);
  const portfolioId = portfolio?.portfolioId;
  const [updateAsOfDateMutation, { data, loading, error }] = useMutation(UPDATE_AS_OF_DATE);

  const updateAsOfDate = async ({ asOfDate, updateData }: UpdateAsOfDate) => {
    const variables = {
      asOfDateUpdateDto: {
        portfolioId,
        asOfDate,
        data: { ...updateData }
      }
    };
    try {
      await updateAsOfDateMutation({ variables });
      toast.success('APM supplemental submitted successfully!');
    } catch (err) {
      toast.error('There was an error submitting the APM supplemental');
    }
  };

  return { updateAsOfDate, data, loading, error };
};
