import { gql } from '@apollo/client';

export const GET_PORTFOLIOS = gql`
  query GetPortfolios {
    portfolios {
      portfolioId
      portfolioCode
      portfolioName
    }
  }
`;
