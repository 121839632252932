import { useGetSkInstitutions, useUpdateSafeKeepingPositions } from '@bakerweb/client-services';
import { selectedPortfolioState } from '@bakerweb/client-state';
import { Pledge, SafeKeepingCode, SafeKeepingPosition, ValidPosition } from '@bakerweb/models';
import { fCurrency, squishSafeKeepingPositions } from '@bakerweb/utils';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Checkbox,
  Divider,
  Drawer,
  DrawerProps,
  FormControl,
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useRecoilValue } from 'recoil';
import { Iconify } from '../iconify/Iconify';
import { Scrollbar } from '../Scrollbar';

interface Props extends DrawerProps {
  positions: ValidPosition[];
  pledgeCodes: Pledge[];
  onClose: VoidFunction;
  filteredPledgeCode: string | null;
  asOfDate: string | null;
  refetchPositions: () => void;
  closeAndClearPositions: () => void;
}

interface DifferencesMap {
  [positionId: string]: number;
}

export function PledgingMultiFlyoutDrawer({
  positions,
  asOfDate,
  pledgeCodes,
  refetchPositions,
  closeAndClearPositions,
  filteredPledgeCode,
  open,
  onClose,
  ...other
}: Props) {
  const [toSkCode, setToSkCode] = useState<SafeKeepingCode | null>(null);
  const [toSkCodeForPledge, setToSkCodeForPledge] = useState<SafeKeepingCode | null>(null);
  const [toPledgeCode, setToPledgeCode] = useState<Pledge | null>(null);
  const [bulkPledgeAcknowledged, setBulkPledgeAcknowledged] = useState(false);
  const [bulkSkAcknowledged, setBulkSkAcknowledged] = useState(false);
  const [releaseAllAcknowledged, setReleaseAllAcknowledged] = useState(false);
  const isMobile = useMediaQuery('(max-width:900px)');
  const { updateSafeKeepingPositions } = useUpdateSafeKeepingPositions();
  const portfolio = useRecoilValue(selectedPortfolioState);
  const { safeKeepingInstitutions } = useGetSkInstitutions();

  const { totalCurrFace, totalOrigFace, totalPledgedAmount } = useMemo(() => {
    let totalCurrFace = 0;
    let totalOrigFace = 0;
    let totalPledgedAmount = 0;

    positions.forEach((position) => {
      totalCurrFace += position.currFaceEnding;
      totalOrigFace += position.origFace;
      totalPledgedAmount += position.safeKeepingPledgedSum;
    });

    return { totalCurrFace, totalOrigFace, totalPledgedAmount };
  }, [positions]);

  const calculatePledgedDifferences = () => {
    const differencesMap: DifferencesMap = {};
    positions.forEach((position) => {
      const sumPledgedAmount = position.safeKeepingPositions.reduce(
        (sum: number, safeKeepingPosition: SafeKeepingPosition) => {
          return safeKeepingPosition.pledgeCode !== '-1' ? sum + safeKeepingPosition.pledgedAmount : sum;
        },
        0
      );

      const difference = position.origFace - sumPledgedAmount;
      differencesMap[position.positionId] = difference;
    });
    return differencesMap;
  };

  const differencesMap = calculatePledgedDifferences();
  const saveBulkPledge = async () => {
    if (bulkPledgeAcknowledged && toPledgeCode && toSkCodeForPledge) {
      try {
        const safeKeepingPositionUpdateDtos = positions.map((position: ValidPosition) => {
          const difference = differencesMap[position.positionId];
          const currentPledgedPositions = position.safeKeepingPositions
            .filter((skp: SafeKeepingPosition) => skp.pledgeCode !== '-1')
            .map((position: SafeKeepingPosition) => ({
              portfolioId: portfolio?.portfolioId,
              asOfDate,
              positionMasterId: position.positionMasterId,
              safekeepingCode: position.safekeepingCode,
              pledgeCode: position.pledgeCode,
              pledgedAmount: position.pledgedAmount
            }));
          const newPledgedPosition = {
            portfolioId: portfolio?.portfolioId,
            asOfDate,
            positionMasterId: position.positionMasterId,
            safekeepingCode: toSkCodeForPledge.safeKeepingCode,
            pledgeCode: toPledgeCode.pledgeCode,
            pledgedAmount: difference
          };
          if (difference > 0) {
            currentPledgedPositions.push(newPledgedPosition);
          }
          const consolidatedPositions = squishSafeKeepingPositions(currentPledgedPositions);
          return {
            positionMasterId: position.positionMasterId,
            asOfDate,
            portfolioId: portfolio?.portfolioId,
            safeKeepingPositions: consolidatedPositions
          };
        });
        await updateSafeKeepingPositions(safeKeepingPositionUpdateDtos);
        closeAndClearPositions();
        await refetchPositions();
      } catch (error) {
        console.error('Error updating pledges:', error);
      } finally {
        setBulkPledgeAcknowledged(false);
        setToSkCodeForPledge(null);
        setToPledgeCode(null);
      }
    } else {
      toast.error('Please select a safekeeping code, pledgee, and acknowledge the change to all CUSIPs.');
    }
  };

  const saveBulkSkChange = async () => {
    if (bulkSkAcknowledged && toSkCode) {
      try {
        const safeKeepingPositionUpdateDtos = positions.map((position: ValidPosition) => {
          const currentPledgedPositions = position.safeKeepingPositions.map((position: SafeKeepingPosition) => ({
            portfolioId: portfolio?.portfolioId,
            asOfDate,
            positionMasterId: position.positionMasterId,
            safekeepingCode: toSkCode.safeKeepingCode,
            pledgeCode: position.pledgeCode,
            pledgedAmount: position.pledgedAmount
          }));
          const consolidatedPositions = squishSafeKeepingPositions(currentPledgedPositions);
          return {
            positionMasterId: position.positionMasterId,
            asOfDate,
            safeKeepingPositions: consolidatedPositions
          };
        });
        await updateSafeKeepingPositions(safeKeepingPositionUpdateDtos);
        closeAndClearPositions();
        await refetchPositions();
      } catch (error) {
        console.error('Error updating pledges:', error);
      } finally {
        setBulkSkAcknowledged(false);
        setToSkCode(null);
      }
    } else {
      toast.error('Please select a safekeeping location and acknowledge the change to all CUSIPs.');
    }
  };

  const saveBulkRelease = async () => {
    if (releaseAllAcknowledged) {
      try {
        const safeKeepingPositionUpdateDtos = positions.map((position) => {
          const uniqueSafeKeepingPositions = position.safeKeepingPositions.map((skp: SafeKeepingPosition) => ({
            portfolioId: portfolio?.portfolioId,
            asOfDate,
            positionMasterId: position.positionMasterId,
            safekeepingCode: skp.safekeepingCode,
            pledgeCode: filteredPledgeCode && skp.pledgeCode === filteredPledgeCode ? '-1' : skp.pledgeCode, // Code for Not Pledged
            pledgedAmount: skp.pledgedAmount
          }));
          const consolidatedPositions = squishSafeKeepingPositions(uniqueSafeKeepingPositions);
          return {
            positionMasterId: position.positionMasterId,
            asOfDate,
            safeKeepingPositions: consolidatedPositions
          };
        });
        await updateSafeKeepingPositions(safeKeepingPositionUpdateDtos);
        await refetchPositions();
        setReleaseAllAcknowledged(false);
      } catch (error) {
        console.error('Error releasing pledges:', error);
      }
    } else {
      toast.error('Please acknowledge the release of all pledges.');
    }
  };

  const handleBulkPledgeAcknowledged = () => {
    setBulkPledgeAcknowledged(!bulkPledgeAcknowledged);
  };

  const handleBulkSkAcknowledged = () => {
    setBulkSkAcknowledged(!bulkSkAcknowledged);
  };

  const handleReleaseAllAcknowledged = () => {
    setReleaseAllAcknowledged(!releaseAllAcknowledged);
  };

  const getOptionLabel = useCallback((option: SafeKeepingCode) => {
    return `${option.safeKeepingName} (${option.safeKeepingCode})`;
  }, []);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      BackdropProps={{
        invisible: false
      }}
      PaperProps={{
        sx: { width: isMobile ? '100%' : '900px' }
      }}
      {...other}
    >
      <Scrollbar sx={{ height: 1 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ p: 2.5 }}>
          <Typography variant="h6"> Manage CUSIPs </Typography>
          <IconButton color="inherit" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Stack spacing={2.5} justifyContent="center" sx={{ p: 2.5 }}>
          <Accordion sx={{ m: '-15px !important' }} disableGutters square elevation={0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h4" color="primary">
                {positions.length} CUSIPs Selected
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {positions.map((pos) => {
                return <Typography key={pos.positionMasterId}>{pos.cusip}</Typography>;
              })}
            </AccordionDetails>
          </Accordion>
          <Divider sx={{ borderStyle: 'dashed' }} />
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column">
              <Typography variant="subtitle2" sx={{ wordBreak: 'break-all' }}>
                Selected Original Face
              </Typography>
              <Typography variant="h4" color="success.dark">
                {fCurrency(totalOrigFace)}
              </Typography>
            </Stack>
            <Stack direction="column">
              <Typography variant="subtitle2" sx={{ wordBreak: 'break-all' }}>
                Selected Current Face
              </Typography>
              <Typography variant="h4" color="success.dark">
                {fCurrency(totalCurrFace)}
              </Typography>
            </Stack>

            <Stack direction="column">
              <Typography variant="subtitle2" sx={{ wordBreak: 'break-all' }}>
                Selected Pledged Amount
              </Typography>
              <Typography variant="h4" color="success.dark">
                {fCurrency(totalPledgedAmount)}
              </Typography>
            </Stack>
          </Stack>

          <Divider sx={{ borderStyle: 'dashed' }} />

          <Stack direction="row" justifyContent="space-between">
            <Stack direction="column">
              <Stack direction="row" justifyContent="space-between" sx={{ mb: 2.5 }}>
                <Typography variant="subtitle2"> Pledge All Available Amounts To Pledgee </Typography>
              </Stack>
              <Stack spacing={2} direction="column" alignItems={{ xs: 'flex-start', md: 'center' }}>
                <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                  <Autocomplete
                    id="toSkCodeForPledge"
                    options={safeKeepingInstitutions}
                    getOptionLabel={getOptionLabel}
                    isOptionEqualToValue={(option, value) => option.recId === value.recId}
                    value={toSkCodeForPledge}
                    onChange={(event, newValue) => {
                      setToSkCodeForPledge(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Choose Safekeeping" />}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Autocomplete
                    id="toPledgeCode"
                    options={pledgeCodes}
                    getOptionLabel={(option) => option.pledgeeName}
                    value={toPledgeCode}
                    onChange={(event, newValue) => {
                      if (newValue && 'recId' in newValue) {
                        setToPledgeCode(newValue);
                      } else {
                        setToPledgeCode(null);
                      }
                    }}
                    renderInput={(params) => <TextField {...params} label="Choose Pledgee" />}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Checkbox checked={bulkPledgeAcknowledged} onChange={handleBulkPledgeAcknowledged} />}
                    label="I am aware this change will affect all selected CUSIPs."
                  />
                </FormControl>
                <FormControl fullWidth sx={{ mt: 3 }}>
                  <Button
                    fullWidth
                    disabled={!bulkPledgeAcknowledged}
                    variant="contained"
                    color="primary"
                    startIcon={<Iconify icon="eva:checkmark-circle-outline" />}
                    onClick={saveBulkPledge}
                  >
                    Save All Pledgee Changes
                  </Button>
                </FormControl>
              </Stack>
            </Stack>
            <Stack direction="column">
              <Stack direction="row" justifyContent="space-between" sx={{ mb: 2.5 }}>
                <Typography variant="subtitle2"> Change All Pledges to SK Code </Typography>
              </Stack>
              <Stack spacing={2} direction="column" alignItems={{ xs: 'flex-start', md: 'center' }}>
                <FormControl fullWidth sx={{ mt: 2, mb: 2 }}>
                  <Autocomplete
                    id="toSkCode"
                    options={safeKeepingInstitutions}
                    getOptionLabel={getOptionLabel}
                    isOptionEqualToValue={(option, value) => option.recId === value.recId}
                    value={toSkCode}
                    onChange={(event, newValue) => {
                      setToSkCode(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Choose Safekeeping" />}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <FormControlLabel
                    control={<Checkbox checked={bulkSkAcknowledged} onChange={handleBulkSkAcknowledged} />}
                    label="I am aware this change will affect all selected CUSIPs."
                  />
                </FormControl>
                <FormControl fullWidth sx={{ mt: 3 }}>
                  <Button
                    fullWidth
                    disabled={!bulkSkAcknowledged}
                    variant="contained"
                    color="primary"
                    startIcon={<Iconify icon="eva:checkmark-circle-outline" />}
                    onClick={saveBulkSkChange}
                  >
                    Save All SK Changes
                  </Button>
                </FormControl>
              </Stack>
            </Stack>
          </Stack>
          <Divider sx={{ borderStyle: 'dashed' }} />
          <Stack>
            <Stack direction="row" justifyContent="space-between" sx={{ mb: 1 }}>
              <Typography variant="subtitle2" color="error">
                Release All Pledges For Selected CUSIPS
              </Typography>
            </Stack>
            <Stack spacing={2} direction="column" alignItems={{ xs: 'flex-start', md: 'center' }}>
              <FormControl fullWidth>
                <FormControlLabel
                  color="error"
                  control={
                    <Checkbox color="error" checked={releaseAllAcknowledged} onChange={handleReleaseAllAcknowledged} />
                  }
                  label="I am aware this change will affect all selected CUSIPs."
                />
              </FormControl>
              <FormControl fullWidth sx={{ mt: 3 }}>
                <Button
                  fullWidth
                  disabled={!releaseAllAcknowledged}
                  variant="contained"
                  color="error"
                  startIcon={<Iconify icon="eva:alert-circle-outline" />}
                  onClick={saveBulkRelease}
                >
                  Release All Pledges
                </Button>
              </FormControl>
            </Stack>
          </Stack>
        </Stack>
      </Scrollbar>
    </Drawer>
  );
}
