import { Icon } from '@iconify/react';
import { Box, BoxProps } from '@mui/material';
import { forwardRef } from 'react';
import { IconifyProps } from './types';

interface Props extends BoxProps {
  icon: IconifyProps;
  width?: string | number; // Ensure width is typed as string | number
}

export const Iconify = forwardRef<SVGElement, Props>(({ icon, width = 20, sx, ...other }, ref) => (
  <Box
    ref={ref}
    sx={{ display: 'inline-flex', width: width as string | number, height: width as string | number, ...sx }}
    {...other}
  >
    <Icon icon={icon} width={width as string | number} height={width as string | number} />
  </Box>
));
