import { useQuery } from '@apollo/client';
import { DeskCdOffering } from '@bakerweb/models';
import { GET_DESK_CD_OFFERINGS } from '../graphql/queries/getDeskCdOfferings';

export const useGetCdOfferings = () => {
  const { data, loading, error, refetch } = useQuery<{ deskCdOfferings: DeskCdOffering[] }>(GET_DESK_CD_OFFERINGS, {
    fetchPolicy: 'cache-first'
  });

  const deskCdOfferings = data?.deskCdOfferings ?? [];

  return { loading, error, deskCdOfferings, refetch };
};
