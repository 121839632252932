import { useQuery } from '@apollo/client';
import { PositionRateShiftResult } from '@bakerweb/models';
import { GET_RATE_SHIFTS } from '../graphql/queries/getPositionRateShifts';

export const useGetPositionRateShifts = (skip = 0, take = 1000, positionIds: string[] | null = null) => {
  const { loading, error, data, refetch } = useQuery<{
    positionRateShiftResults: PositionRateShiftResult[];
  }>(GET_RATE_SHIFTS, {
    variables: { skip, take, positionIds },
    skip: !positionIds || !positionIds.length,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first'
  });

  const positionRateShiftResults = data?.positionRateShiftResults ?? [];

  return { loading, error, positionRateShiftResults, refetch };
};
