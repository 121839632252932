import { gql } from '@apollo/client';

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($userUpdateDto: UserUpdateDto!) {
    updateUser(userUpdateDto: $userUpdateDto) {
      email
      enabled
      accounts {
        id
        name
        portfolioCodes
      }
      portal
      portfolioCodes
      permissions {
        pledging
        pledgeAndRelease
        apm
        files
        cds
        muniCredit
      }
    }
  }
`;
