import { gql } from '@apollo/client';

export const GET_INSTITUTIONS = gql`
  query GetInstitutions($institutionIds: [String!]) {
    institutions(institutionIds: $institutionIds) {
      city
      state
      institutionId
      institutionName
      institutionType
    }
  }
`;
