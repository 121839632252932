import { FileList } from '@bakerweb/models';
import { useCallback, useState } from 'react';
import { clientHttp } from '../client.http';

interface FileListResult {
  success: FileList[];
  errors: unknown[];
}

export const useS3FileList = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>([]);
  const [success, setSuccess] = useState<FileList[]>([]);

  const getFiles = useCallback(async (path: string): Promise<FileListResult> => {
    setLoading(true);
    setError([]);
    setSuccess([]);

    try {
      const urlFragment = `/object/list/${encodeURIComponent(path)}`;
      const response = await clientHttp(urlFragment, {
        method: 'GET'
      });

      setSuccess(response);
      return { success: response, errors: response.errors || [] };
    } catch (error) {
      setError(error);
      return { success: [], errors: [error] };
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    error,
    success,
    getFiles
  };
};
