import { Box, BoxProps } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export function BakerSoftwareSolutionsLogo({ sx }: BoxProps) {
  const theme = useTheme();
  const PRIMARY_MAIN = theme.palette.primary.main;

  return (
    <Box sx={{ width: 170, height: 170, ...sx }}>
      <img
        src="/assets/Baker-Software-Solutions-Logo.png"
        alt="Baker Loan Solutions Logo"
        style={{ width: '100%', height: '100%', objectFit: 'contain', fill: PRIMARY_MAIN }}
      />
    </Box>
  );
}
