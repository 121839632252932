import { gql } from '@apollo/client';

export const GET_POSITIONS = gql`
  query GetPositions(
    $skip: Int = 0
    $take: Int = 1000
    $portfolioId: String!
    $institutionId: String
    $asOfDate: String
  ) {
    positions(skip: $skip, take: $take, portfolioId: $portfolioId, institutionId: $institutionId, asOfDate: $asOfDate) {
      positionId
      positionMasterId
      portfolioId
      institutionId
      swapPortfolioId
      acctgRep
      brokerCode
      portfolioCode
      asOfDate
      isDummyCusip
      cusip
      description
      secGroup
      secClass
      secID
      pool
      ticker
      collateralType
      trancheType
      escrowType
      hybridType
      dealType
      cDType
      balloonTerm
      certificateNumber
      countryLocked
      country
      dataVendor
      dataVendorKey
      maturityDate
      wAMDate
      issueDate
      intAccrualDate
      firstCouponDate
      coupon
      couponProj
      couponPrior
      couponPrior2
      couponDate
      couponDateProj
      couponDatePrior
      couponDatePrior2
      isUsingDividend
      accrualRate
      couponFrequency
      compoundingFrequency
      principalFrequency
      paymentIntClass
      dayCountMethod
      poolType
      origFace
      origFacePrior
      currFace
      currFacePrior
      currFaceEnding
      factor
      factorProj
      factorPrior
      factorPrior2
      factorDate
      factorDateProj
      factorDatePrior
      factorDatePrior2
      factorLocked
      factorManual
      usingEstimatedFactor
      pledgedAmount_Plugged
      pSA
      pAC_H_PSA
      pAC_L_PSA
      cPR
      prepayType
      prepaymentPenaltyOverride
      prepaymentDeferral
      prepaymentDeferralStartDate
      prepaymentDeferralLocked
      prepaymentPenalty
      callInformationOverride
      isCallable
      callFrequency
      firstCallDate
      callDate
      callPrice
      callDays
      callCushionUsed
      callCushionOverride
      callScheduleManual
      callScheduleManualId
      prerefundedDate
      prerefundedPrice
      bookPrice
      bookPriceOrig
      bookPricePrior
      bookPricePriorOrig
      bookPriceFinal
      bookPrice32
      bookYield
      bookYieldPrior
      bookDiscount
      bookValue
      bookValueOrig
      bookValuePriorOrig
      bookValuePrior
      bookValueFinal
      bookBEEM
      marketPrice
      marketPricePrior
      marketPrice32
      marketYield
      marketDiscount
      marketValue
      marketValuePrior
      importedMarketPrice
      pricingSourceCode
      pricingDate
      pricingScore
      dividend
      shares
      acctgCPR
      acctgPSA
      issueSize
      issuePrice
      fAS115
      fAS115Locked
      gainLoss
      gainLossPrior
      sinkScheduleManualId
      stateTaxExclusionAmount
      stateTaxExclusionAmountLocked
      taxStatus
      stepDate1
      stepDate2
      stepDate3
      stepDate4
      stepDate5
      stepDate6
      stepDate7
      stepCpn1
      stepCpn2
      stepCpn3
      stepCpn4
      stepCpn5
      stepCpn6
      stepCpn7
      stepBkYld1
      stepBkYld2
      stepBkYld3
      stepBkYld4
      stepBkYld5
      stepBkYld6
      stepBkYld7
      lifeCap
      lifeFloor
      periodicCollar
      firstPeriodicCollar
      nextPeriodicCollar
      lastResetDate
      nextResetDate
      accrualDateStart
      lastIntPayDate
      nextIntPayDate
      floatingIndexFormulaOverride
      floatingIndexRateOverride
      resetFrequency
      indexLeverage
      indexLeverage2
      floatingIndex
      floatingIndex2
      margin
      margin2
      addSubIndex
      addSubIndex2
      addSubIndex3
      fIC
      fIC_Restricted
      servicingFee
      wAC
      wAM
      wALA
      origTerm
      remainIoPeriod
      origIoTerm
      waLTV
      delay
      stateCode
      sinkScheduleManual
      muniInsurer
      riskWeight
      riskWeightLocked
      rcbLineNumber
      rcbLineNumberLocked
      rcbMemoLineNumber
      rcbMemoLineNumberLocked
      rcbMemoSupplementLineNumber
      rcbMemoSupplementLineNumberLocked
      rckLineNumber
      rckLineNumberLocked
      analyticsOverride
      marketYield_ToMaturity
      marketYield_ToCall
      marketYield_ToWorst
      marketYield_ToSink
      marketYield_TE_ToMaturity
      marketYield_TE_ToCall
      marketYield_TE_ToWorst
      marketYield_TE_ToSink
      bookYield_ToMaturity
      bookYield_ToCall
      bookYield_ToWorst
      bookYield_ToSink
      bookYield_TE_ToMaturity
      bookYield_TE_ToCall
      bookYield_TE_ToWorst
      bookYield_TE_ToSink
      bookYield_Static_ToMaturity
      bookYield_Static_ToCall
      bookYield_Static_ToWorst
      isBackPurchase
      backPurchaseEntryDate
      purchaseLocked
      purchasePrice
      purchasePrice32
      purchasePriceOverride
      purchaseDiscount
      purchaseYield
      purchaseYield_TE
      purchaseDate
      purchaseTradeDate
      purchaseFactor
      purchaseFactorDate
      purchaseSpeed
      purchaseOrigFace
      purchaseOrigFaceInitial
      parCost
      purchasePrincipal
      purchaseInterest
      purchaseAvgLife
      purchaseEffDur
      purchaseEffConvex
      purchaseDealerCode
      sharesPrice
      sharesBotSold
      isConversionMonth
      conversionDate
      conversionPrice
      disposedRemoved
      disposedLocked
      disposedTransCode
      disposedBookValue
      disposedPrice
      disposedPrice32
      disposedYield
      disposedYield_TE
      disposedDate
      disposedTradeDate
      disposedFactor
      disposedFactorDate
      disposedCoupon
      disposedSpeed
      disposedOrigFace
      disposedPrincipal
      disposedInterest
      disposedGainLoss
      disposedGainLossFinal
      disposedAcctgAmortFinal
      disposedAvgLife
      disposedEffDur
      disposedEffConvex
      disposedDealerCode
      nextMonthDisposedInterest
      paydownPrincipal
      paydownGainLoss
      nextPrincipalPayment
      nextInterestPayment
      yTM
      yTC
      isEscrowedToMaturity
      isStructuredNote
      isStructuredNoteLocked
      isAgencyBacked
      isVariable
      isSinkable
      isStepUp
      isStepUpAtIssue
      isFactored
      isConvertible
      isBalloon
      isHybrid
      isMbsGold
      isCMBSCMO
      isCMBSMBS
      isDUS
      isReversible
      isPrivate
      isCommercialPaper
      isMoneyMarket
      isMuniLease
      isIO
      isGoRevCopLocked
      isGO
      isRevenue
      isCOP
      isBankQualified
      isInsured
      isFdicGtd
      isNcuaGtd
      isCuIssued
      isTaxable
      isStateTaxable
      isFederalTaxable
      isSubjectToAMT
      isSafeHarborVerified
      isOID
      isNonAccrual
      isDailyPriced
      isManualWriteDown
      isWarrant
      isCRA
      isRangeBond
      cRAStateCode
      servicer
      spreadShiftMatrixIndexCode
      optionOverrideSpreadShiftMatrix
      prepaymentSpeedMatrixSecId
      optionOverridePrepaymentSpeedMatrix
      optionOverridePricingSpreads
      optionOverrideYieldCurveIndexId
      yieldCurveIndexId
      optionOverrideAddnlSpread
      addnlSpread
      usingSpeedsSource
      usingSpeedType
      usingSpeedType_Historical
      redemptionValue
      optionUseOID
      oIDYield
      oIDPrice
      optionEnterShares
      optionEnterFace
      optionEnterMarket
      optionEnterBook
      optionEnter3rdParty
      optionEnter3rdPartyOverride
      optionPriceTo
      optionWeekendPayments
      optionCalcDuration
      optionSetYieldsToZero
      optionCashflowMethod
      optionCmoTypeMethod
      optionShockSpreadMethod
      optionCurveAdjustPercent
      optionSpeedsSource
      optionPrepayMethodLocked
      optionPrepayMethod
      optionAcctgPrepayMethodLocked
      optionAcctgPrepayMethod
      optionAcctgMethod
      optionAcctgMethodBkup
      optionAcctgMethodLocked
      optionAcctgProRataAccretion
      optionAcctgBookPriceFloat
      optionAcctgBookFinalDate
      optionAcctgAmortizeTo
      optionAcctgAmortizeToLocked
      optionAcctgGenerateAmortSchedule
      optionAcctgAmortScheduleHorizonDate
      optionOverrideAccruedInterestValues
      optionOverrideBookValueReconciliationValues
      acctgPrepaySpeedUsing
      acctgPrepayMethodUsing
      acctgPrepayMethodLongestAvail
      acctgYield_Beg
      acctgYield
      acctgYieldPrior
      acctgYield_TE
      acctgYieldPrior_TE
      acctgYieldAvgCoupon
      acctgYieldAvgCouponPrior
      acctgYieldAvgCoupon_TE
      acctgYieldAvgCouponPrior_TE
      acctgAvgLife
      acctgAvgLifePrior
      acctgDur
      acctgDurPrior
      acctgPricedTo
      acctgAmortAdjusted
      acctgAmort
      acctgAmortYtdPrior
      acctgAmort_DLY
      acctgAmort_LTD
      acctgAmort_MTD
      acctgAmort_ProRata
      acctgAmort_Remaining
      acctgBookPrice
      acctgBookValue
      acctgGainOnPaydown
      acctgIntAccrual_BOP
      acctgIntAccrual_DLY
      acctgIntAccrual_MTD
      acctgIntAccrual_YTD
      acctgIntCurrent
      acctgIntNext
      acctgIntReceivable
      acctgIntTransaction
      acctgIntDeferred_MTD
      acctgIntDeferred_Cumm
      acctgIntDeferred_Cumm_Ending
      acctgPrinc_MTD
      acctgPrinc_YTD
      acctgIntDateLast
      acctgIntDateNext
      acctgIntAccrualDays_BOP
      acctgIntAccrualDays_MTD
      acctgNextMonthPrincipal
      lastInterestReceivable_Calcd
      lastInterestReceivable_Prior
      lastInterestReceivable_Adjustment
      interestPurchased
      interestAccruedAdjusted
      interestAccrued
      interestAccruedYtdPrior
      soldInterest
      soldInterestDate
      calledInterest
      calledInterestDate
      interestPayment
      interestPaymentYtdPrior
      interestPaymentDate
      interestReceivable
      principalPurchased
      principalDisposed
      monthlyYield
      netIncome
      userNotes
      reportNotes
      transCode
      transDate
      transTradeDate
      transOrigFace
      transPrice32
      transCoupon
      transSpeed
      transFactor
      transFactorDate
      transDealerCode
      transConfirmId
      transYield
      transAvgLife
      transEffDur
      transEffConvex
      transYield_TE
      transInterest
      transPrincipal
      proofPrincipal
      proofInterest
      proofYield
      transferOverride
      transferLocked
      transferDate
      transferOption
      transferFromBookPrice
      transferFromBookValue
      transferToMarketPrice
      transferToMarketValue
      transferFactor
      transferValue
      transferBookValuePrior
      transferBookPricePrior
      transferMarketValuePrior
      transferCurrFacePrior
      transferPrincipalDisposed
      transferDisposedGainLoss
      transferNextPrincipalPayment
      transferPaydownPrincipal
      transferPaydownGainLoss
      transferAmort
      transferAmort_LTD
      transferAmort_Remaining
      transferBookValue
      transferBookPrice
      transferMarketValue
      transferCurrFace
      transferChangeGainLoss
      transferRemTransAmountPrior
      transferRemTransAmount
      transferUsePriorBookAndMarket
      transferNetValuePrior
      transferNetValue
      transferBookOption
      transferMarketOption
      transferAcctgMethod
      transferAcctgAmortizeTo
      isOTTI
      oTTIOption
      oTTIUserWriteDownAmount
      oTTIPrincipalLossPercent
      oTTIPrincipalLossLocked
      oTTIInterestShortfallPercent
      oTTIInterestShortfall
      oTTIPrincipalLoss
      exchangeLocked
      exchangeOrigPositionId
      exchangeOrigCusip
      exchangeNewCusip
      exchangeTradeDate
      exchangeSettleDate
      exchangeOrigBookValue
      exchangeOrigBookPrice
      exchangeNewBookValue
      exchangeNewBookPrice
      exchangeCompensationFee
      exchangeOrigPool
      exchangeOrigIssueDate
      dec2017_TaxRateChangePriorFedRate
      dec2017_TaxRateChangeNewFedRate
      dec2017_TaxRateChangeOrigPar
      dec2017_TaxRateChangeAdjustment
      dec2017_TaxRateChangeAdjustment_MTD
      swapSettleDateOverride
      optionSwapLockBookValueOrigValues
      optionSwapNoAdjustmentsToPBV
      optionSwapUserManageBookPriceOnBuySide
      isFedFundsAcctForSwap
      transTicketId
      deskPrice
      deskPrice32
      salesCredit32
      indicationNotes
      usingStressTest
      stressTestResult
      excludeFromIpaReports
      excludeFromApmReports
      suppressMsg_PriorBkPxHighLowThanPurchPx
      tPG_ABS_RF
      tPG_TRANS_NO
      importedAgyDataDate
      importedTpsDataDate
      importedAbsDataDate
      importedMbsDataDate
      importedCmoDataDate
      firstFullUpdate
      lastImportedDate
      createdDt
      createdBy
      modifiedDt
      modifiedBy
      lastFullUpdate
      isSelectedToSell
      isInProcessingQueue
      pqCreateDt
      fromPositionId
      fromPortfolioId
      legacySecGroup
      legacySecClass
      legacySecId
      legacyIpaSecId
      apmRptSecClass
      isSelected
      isDirty
      deletionId
      isDeleted
      yieldMaintenanceDate
      useProjectedForAmortSchedules
      usingSpeedsSourceSubType
      useBaseCashFlowsForAllRateScenarios
      useZeroRateShocks
      usingResetSchedule
      optionOverrideHistoricalSpeeds
      dailyPricingUsesPledgedAmount
      optionPointOnCurve
      sinkScheduleIgnoreInAnalytics
      optionStopMakingPennyAdjustmentsToBOP
      issueSizeRemaining
      purchaseSpeedImportedFromDataVendor
      balloonDate
      ioTerm
      storedTrueYields
      isCorporateCD
      bidIndies_PriceID
      isDebtSecurityIssuedByDepositoriesBanksCU
      isProblemBond
      rating {
        spRating
        moodyRating
      }
      safeKeepingPledgedSum
      safeKeepingPositions {
        recId
        positionMasterId
        safekeepingCode
        pledgeCode
        pledgedAmount
        pledgee {
          pledgeeName
          pledgeCode
        }
        currFace
      }
    }
  }
`;
