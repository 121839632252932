import { Box, BoxProps } from '@mui/material';
import { motion } from 'framer-motion';
import { varWrapEnter } from './variants';

interface MotionContainerProps extends BoxProps {
  initial?: boolean | string;
  open?: boolean;
}

export function MotionContainer({ open, children, ...other }: MotionContainerProps) {
  return (
    <Box {...other}>
      <motion.div initial={false} animate={open ? 'animate' : 'exit'} variants={varWrapEnter}>
        {children}
      </motion.div>
    </Box>
  );
}
