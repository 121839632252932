import { ApolloProvider } from '@apollo/client';
import { environment } from '@bakerweb/client-environment';
import { gqlClient } from '@bakerweb/client-services';
import { CollapseDrawerProvider, SettingsProvider } from '@bakerweb/theme';

import { LicenseInfo } from '@mui/x-license';

import { Amplify } from 'aws-amplify';
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { App } from './app/App';

Amplify.configure(environment.awsExports);

LicenseInfo.setLicenseKey(
  'a3a89c7bca5da8e12aeb47c3f85ebcc9Tz04NjgwMSxFPTE3NDI0OTc5MTAwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y'
);

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const root = createRoot(container);
root.render(
  <StrictMode>
    <HelmetProvider>
      <SettingsProvider
        theme={{
          themeMode: 'light',
          themeDirection: 'ltr',
          themeColor: 'blue',
          themeStretch: true
        }}
      >
        <CollapseDrawerProvider>
          <BrowserRouter>
            <ApolloProvider client={gqlClient}>
              <RecoilRoot>
                <App />
              </RecoilRoot>
            </ApolloProvider>
          </BrowserRouter>
        </CollapseDrawerProvider>
      </SettingsProvider>
    </HelmetProvider>
  </StrictMode>
);
