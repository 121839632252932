import { useMediaQuery } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { PledgeCodesForm } from './PledgeCodesForm';

export function PledgeCodesModal() {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:900px)');
  const style = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: isMobile ? '100%' : '900px',
    height: isMobile ? '100%' : '600px',
    overflow: 'auto',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="outlined" fullWidth onClick={handleOpen}>
        Manage Pledge Codes
      </Button>
      <Modal
        aria-labelledby="Manage Pledge Codes"
        aria-describedby="Manage Pledge Codes"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <PledgeCodesForm />
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
