import { useGetAsOfDates } from '@bakerweb/client-services';
import { CircularProgress, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { MouseEvent, useEffect, useState } from 'react';

interface ReportDateToggleProps {
  onDateSelect: (date: string) => void;
}

export function ReportDateToggle({ onDateSelect }: ReportDateToggleProps) {
  const { asOfDates, loading } = useGetAsOfDates();
  const [selectedDate, setSelectedDate] = useState('');

  useEffect(() => {
    if (asOfDates.length > 0 && !selectedDate) {
      setSelectedDate(asOfDates[0].asOfDate);
      onDateSelect(asOfDates[0].asOfDate);
    }
  }, [asOfDates, selectedDate, onDateSelect]);

  useEffect(() => {
    if (asOfDates.length > 0) {
      const firstTwoAsOfDates = asOfDates.slice(0, 2);
      const defaultDate = firstTwoAsOfDates[0].asOfDate;
      setSelectedDate(defaultDate);
      onDateSelect(defaultDate);
    }
  }, [asOfDates, onDateSelect]);

  const handleDateChange = (event: MouseEvent<HTMLElement>, newDate: string) => {
    setSelectedDate(newDate);
    onDateSelect(newDate);
  };

  if (loading) {
    return <CircularProgress size={20} />;
  }

  return (
    <ToggleButtonGroup
      fullWidth
      size="small"
      color="primary"
      value={selectedDate}
      exclusive
      onChange={handleDateChange}
      aria-label="date selector"
    >
      {asOfDates
        .slice(0, 2)
        .reverse()
        .map(({ asOfDate }) => (
          <ToggleButton color="primary" key={asOfDate} value={asOfDate} aria-label={asOfDate}>
            {asOfDate}
          </ToggleButton>
        ))}
    </ToggleButtonGroup>
  );
}
