import { InputAdornment, TextField, TextFieldProps } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

type Props = TextFieldProps & {
  name: string;
  label?: string;
  helperText?: string;
  textColor?: string;
};

export function RHFNumericField({ name, label, helperText, textColor, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value, ref, ...rest }, fieldState: { error } }) => (
        <NumericFormat
          customInput={TextField}
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            style: { color: textColor ?? 'inherit' }
          }}
          thousandSeparator
          placeholder="0"
          value={value}
          onValueChange={(values) => {
            onChange(values.floatValue ?? undefined);
          }}
          onBlur={onBlur}
          getInputRef={ref}
          label={label}
          helperText={error ? error.message : helperText}
          error={!!error}
          fullWidth
        />
      )}
    />
  );
}
