/**
 DO NOT add local, transient, router, or any non-global state to this file.
 **/

import { UserPortfolio, UserViewPermissions } from '@bakerweb/models';
import { atom } from 'recoil';

export const userPortfolioState = atom<UserPortfolio[]>({
  key: 'userPortfolioState',
  default: []
});

export const userPermissionsState = atom<UserViewPermissions>({
  key: 'userPermissionsState',
  default: {
    pledging: false,
    apm: false,
    files: false,
    cds: false
  }
});

export const selectedPortfolioState = atom<UserPortfolio | undefined>({
  key: 'selectedPortfolioState',
  default: undefined
});
