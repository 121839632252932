import { userPermissionsState } from '@bakerweb/client-state';
import type { UserViewPermissions } from '@bakerweb/models';
import { useRecoilValue } from 'recoil';

export const useHasPermission = () => {
  const permissions = useRecoilValue(userPermissionsState);
  const hasPermissionToViewPath = (path: string) => {
    const permissionsKeys = Object.keys(permissions);

    const key = permissionsKeys.find((permissionKey) => path.includes(permissionKey));
    if (key) {
      const permissionSetting = permissions[key as keyof UserViewPermissions];
      return permissionSetting ?? false;
    }
    return true;
  };
  return { hasPermissionToViewPath };
};
